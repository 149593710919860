import Swal from 'sweetalert2';

import { add_route, derive_from, navigate_to } from './singlepage';
import { alert_success } from './alert-banners';
import { api } from './api';
import { action_button, load_page, load_template } from './ui';
import { bind_mqtt_event } from './events';

$(window).bind('singlepagesetup', function()
	{
		async function setup_host_edit(container, host) {
			const identity = await window.identity;

			if (identity.has_ability('user.search')) {
				container.find('form').find('#input-host-owner').userInput();
			}

			container.find('form').on('success', (event, result) => {
				alert_success(`Successfully saved the host "${result.name}".`);
			});
		}

		add_route('hosts', function(matches)
			{
				let page = load_page('hosts'),
					sites = api('GET', 'hosts');

				Promise.all([page, sites]).then(values => {
					let html = values[0],
						hosts = values[1];

					$('.table-sites').DataTable({
						data: hosts,
						pageLength: 50,
						columns: [
							{
								data: 'name',
							},
							{
								data: 'owner.principal',
							},
							{
								orderable: false,
								className: 'actions',
								width: '0',
								render: (cell, context, row) => {
									let html = '';
									html += action_button(
										'Delete Host',
										'trash',
										'danger',
										'btn-delete-host',
										`host/${row.id}/delete`,
									);

									html += action_button(
										'Edit Host',
										'pencil-alt',
										'warning',
										'btn-edit-host',
										'host/' + row.id
									);

									return html;
								},
							}
						]
					});
				});
			});

		add_route('host/:id(guid)', async function(args)
			{
				const [host, ek, mdm] = await Promise.all([
					api('GET', 'host/' + args.id),
					api('GET', `host/${args.id}/endorsement_key`),
					api('GET', `host/${args.id}/mdm/status`),
				]);

				host.endorsement_key = ek;
				host.mdm = mdm;
				host.mdm.ios_regexp = /^(iPhone|iPad|iPod)/;

				const container = await load_template('host/edit', host);

				await setup_host_edit(container, host);

				bind_mqtt_event({"thing": "host", "tags": {"host": host.id}}, mqev => {
					switch(mqev.event) {
						case 'seen':
							console.info("host seen event:", mqev);

							container.find('span.host-last-seen')
								.data('timestamp', Math.floor((new Date()).getTime() / 1000))
								.prettyTimestamp();
							
							if (mqev.tags.iface) {
								for (const iface of host.interfaces) {
									if (iface.id !== mqev.tags.iface) {
										continue;
									}

									container.find('.last-iface-name').text(iface.name);

									if (mqev.tags.ip) {
										alert_success(`This host was just seen on interface "${iface.name}" from IP address ${mqev.tags.ip}`);
									}
									break;
								}
							}
							if (mqev.tags.ip) {
								const labelCls = mqev.tags.ip.indexOf(':') > -1 ? '.last-inet6' : '.last-inet4';
								container.find(labelCls).text(mqev.tags.ip);
							}
							break;
					}
				});
			});
		
		add_route('host/:id(guid)/delete', async function(args)
			{
				await derive_from('hosts');

				const host = await api('GET', `host/${args.id}`);
				const answer = await Swal.fire({
                    title: 'Delete host?',
                    text: `This will permanently delete the host "${host.name}" and any reservations it has.`,
                    showCancelButton: true,
                    confirmButtonText: 'Delete Host',
				});

				if (answer.isConfirmed) {
				    await api('DELETE', `host/${host.id}`);
				    alert_success(`Successfully deleted host "${host.name}"`);
				}
				navigate_to('hosts');
			});
		
		add_route('host/:id(guid)/mdm/unenroll', async function(args)
			{
				await derive_from(`host/${args.id}`);

				const host = await api('GET', `host/${args.id}`);

				const answer = await Swal.fire({
					icon: 'warning',
					title: 'Unenroll device from MDM?',
					text: 'Upon the next checkin, all configuration profiles will be removed from this device.',
					showCancelButton: true,
				});

				if (answer.isConfirmed) {
					await api('DELETE', `host/${host.id}/mdm`);
					alert_success(`Successfully cleared MDM enrollment state for "${host.name}".`);

					host.mdm = {'enrollment_stats': {'enrolled': false}};

					const container = await load_template('host/edit', host);
					await setup_host_edit(container, host);
				}
				navigate_to(`host/${host.id}`);
			});
		
		add_route('host/:id(guid)/tpm-reset', async function(args)
			{
				await derive_from(`host/${args.id}`);
				
				const host = await api('GET', `host/${args.id}`);

				const answer = await Swal.fire({
					icon: 'warning',
					title: 'Clear Trusted Computing keys?',
					text: 'This will delete the record of this host\'s Endorsement Key from Machines. The next time the host attests to Machines, its current endorsement key will be recorded.',
					showCancelButton: true,
				});

				if (answer.isConfirmed) {
					await api('DELETE', `host/${host.id}/endorsement_key`);
					alert_success(`Successfully cleared TPM state for host "${host.name}".`);

					host.endorsement_key = {'found': false};

					const container = await load_template('host/edit', host);
					await setup_host_edit(container, host);
				}
				navigate_to(`host/${host.id}`);
			});

		add_route('host/create', async function(args)
			{
				const [suggested_hostname, container, identity] = await Promise.all([
					api('GET', 'host/random'),
					load_template('host/edit', {}),
					window.identity,
				]);

				container.find('input[name="name"]').val(suggested_hostname.name);

				if (identity.has_ability('user.search')) {
                    container.find('form').find('#input-host-owner').userInput();
                }
				container.find('form').on('success', (event, result) => {
                    alert_success(`Successfully created the host "${result.name}"`);
                    navigate_to(`host/${result.id}`);
				});
			});

		add_route('host/:host(guid)/interface/:id(guid)', async function(args)
			{
				const iface = await api('GET', 'interface/' + args.id);
				const container = await load_template('interface/edit', iface);

				container.find('form[name="interface"]').on('success', (event, result) => {
                    alert_success(`Successfully saved the interface "${result.name}".`);
				});
			});

		add_route('host/:host(guid)/interface/:id(guid)/delete', async function(args)
		    {
		        const iface = await api('GET', 'interface/' + args.id);

		        const answer = await Swal.fire({
                    icon: 'warning',
                    title: 'Delete this interface?',
                    text: `The host will lose network access on captive portal networks. Deleting the interface ${iface.name} will also remove any reservations it has.`,
                    showCancelButton: true,
                });

                if (answer.isConfirmed) {
                    await api('DELETE', `interface/${iface.id}`);
                    navigate_to(`host/${iface.host.id}`);
                    alert_success(`Successfully deleted the interface "${iface.name}" from the host "${iface.host.name}"`);
                }
                else {
                    push_state(`host/${iface.host.id}/interface/${iface.id}`);
                }
		    });

		add_route('host/:id(guid)/interface/create', async function(args)
			{
			    const [host, unclaimed] = await Promise.all([
                    api('GET', 'host/' + args.id),
                    api('GET', 'interfaces/unclaimed'),
                ]);

				const container = await load_template('interface/edit', {host, unclaimed});
				container.find('form').on('success', (event, result) => {
                    alert_success(`Successfully created the interface "${result.name}"`);
                    navigate_to(`host/${args.id}/interface/${result.id}`);
				});
			});

		add_route('host/:id(guid)/interface/claim/:iface(guid)', async function(args)
			{
			    const [_, host] = await Promise.all([
                    derive_from(`host/${args.id}/interface/create`),
                    api('GET', `host/${args.id}`),
                ]);

			    const answer = await Swal.fire({
			            icon: 'question',
			            title: 'Claim this interface?',
			            text: "This interface was seen on the network but isn't associated " +
                            `with a host yet. Associating it with the host "${host.name}" will ` +
                            "give it network access and cause the host's DNS records to be " +
                            "updated when this interface is seen by a DHCP server.",
                        showCancelButton: true,
                        confirmButtonText: 'Claim Interface',
			    });

			    if (!answer.isConfirmed) {
			        navigate_to(`host/${args.id}/interface/create`);
			        return;
			    }

			    const result = await api('POST', `host/${args.id}/claim/${args.iface}`);
			    navigate_to(`host/${args.id}`);
			    alert_success('The selected interface has been associated to this host.');
			});

		add_route('host/:host(guid)/interface/:id(guid)/reservation/create', async function(args)
		    {
		        const origin = `host/${args.host}/interface/${args.id}`;
		        await derive_from(origin);

		        const $modal = $('#modal-create-reservation');

		        $modal.modal('show').unbind('hidden.bs.modal').on('hidden.bs.modal',
		               event => navigate_to(origin));

		        const domains = await api('GET', 'domains');
		        const $domain = $modal.find('#select-reservation-create-domain');
		        $domain.prop('readonly', true);
		        $domain.empty().append(
		            $('<option />').prop('disabled', true).prop('selected', true).attr('value', '').text('Select...'));
		        domains.forEach(domain => $domain.append(
		            $('<option />').attr('value', domain.id).text(domain.name)
		        ));
		        $domain.attr('readonly', false);

		        const $range = $modal.find('select[name="range"]');

		        async function updateRanges(event)
		        {
                    const domain = $domain.val();
                    if (!domain) {
                        return;
                    }

                    const af = $modal.find('input:radio:checked[name="af"]').val();

                    const ranges = await api('GET', `domain/${domain}/ranges`);
                    $range.empty().append(
                        $('<option />').prop('disabled', true).prop('selected', true).attr('value', '').text('Select...'));
                    ranges.forEach(range => {
                        if ((af === 'inet' && (!range.inet4_start || !range.inet4_end)) ||
                            (af === 'inet6' && (!range.inet6_start || !range.inet6_end))
                        ) {
                            // skip this range if it doesn't specify an address
                            // range for the chosen address family
                            return;
                        }

                        let rangeStr = af === 'inet' ?
                            `${range.inet4_start} - ${range.inet4_end}` :
                            `${range.inet6_start} - ${range.inet6_end}`;
                        $range.append(
                            $('<option />').attr('value', range.id)
                                .text(`${range.name} (${rangeStr})`)
                        );
                    });

                    $range.attr('readonly', false);
		        }

		        $domain.unbind('change').on('change', updateRanges);
                $modal.find('input:radio[name="af"]').unbind('change').on('change', updateRanges);
                $modal.find('input:radio[name="allocation_mode"]').unbind('change').on('change', event => {
                    let strategy = $(event.currentTarget).attr('value');
                    $modal.find('.show-if-allocation-mode:not(.' + strategy + ')').hide()
                        .find('input, select').prop('disabled', true);
                    $modal.find('.show-if-allocation-mode.' + strategy).show()
                        .find('input, select').prop('disabled', false);
                });
                $modal.find('input:radio:checked[name="allocation_mode"]').trigger('change');

                $modal.find('input, select').on('change', event =>
                    {
                        const af = $modal.find('input:radio:checked[name="af"]').val(),
                            allocation_mode = $modal.find('input:radio:checked[name="allocation_mode"]').val(),
                            range = $range.val(),
                            address = $modal.find('input[name="address"]').val();

                        // FIXME real IP validation
                        let enabled = false;
                        switch (allocation_mode) {
                            case 'explicit':
                                enabled = address.length > 0;
                                break;
                            case 'increment':
                            case 'random':
                                enabled = !!range;
                                break;
                        }

                        if (enabled) {
                            $modal.find('button:submit').removeClass('disabled');
                        }
                        else {
                            $modal.find('button:submit').addClass('disabled');
                        }
                    });

                $modal.find('form').on('success', (event, result) => {
                    $modal.modal('hide');
                    alert_success(
                        `Successfully allocated the address ${result.address}`
                    );
                });
		    });

		add_route('host/:host(guid)/interface/:id(guid)/reservation/:res(guid)/delete', async function(args)
		    {
		        const origin = `host/${args.host}/interface/${args.id}`;
		        await derive_from(origin);

		        const answer = await Swal.fire({
                    title: 'Delete reservation?',
                    text: 'This will cause this host to have a dynamic IP.',
                    showCancelButton: true,
		        });

		        if (answer.isConfirmed) {
		            await api('DELETE', `reservation/${args.res}`);
		            alert_success("Reservation deleted");
		        }
                navigate_to(origin);
		    });
	});
import { api } from './api';
import { nj } from './nunjucks-environment';

$(() => {
    $('.page-content').on('content-load', function()
        {
            $(this).find('.log-container').each((i, e) => {
                const thing = $(e).data('thing'),
                    thing_id = $(e).data('id');

                if (thing && thing_id) {
                    setup_log_viewer($(e), thing, thing_id);
                }
            });
        });
});

async function setup_log_viewer($target, thing, thing_id)
{
    $target.empty().html(
        (await nj()).render('log-viewer')
    );

    const $table = $target.find('table.log-entries');

    $target.find('.log-entries').DataTable({
            ajax: async function(data, callback, settings) {
                const dtapi = $.fn.DataTable.Api(settings),
                    pinfo = dtapi.page.info(),
                    limit = pinfo.length,
                    offset = pinfo.length * pinfo.page;

                const uri = `log/${thing}/${thing_id}?limit=${limit}&offset=${offset}`,
                    response = await api('GET', uri);

                callback({
                    data: response.entries,
                    recordsTotal: response.count,
                    recordsFiltered: response.count,
                });
            },
            serverSide: true,
            pageLength: 25,
            lengthChange: false,
            searching: false,
            ordering: false,
            columns: [
                {
                    data: "timestamp",
                    render: function(cell, context, row) {
                        return $('<span />')
                            .addClass('timeago')
                            .attr('data-timestamp', cell)
                            .outerHTML();
                    }
                },
                {
                    data: "principal",
                    render: function(cell, context, row) {
                        const icon = row.authorizer == 'OAuthAuthorizer' ? 'fa-code' : 'fa-user';
                        return $('<span />')
                            .append($('<i />').addClass(['fas', icon]))
                            .appendText(' ')
                            .appendText(row.principal)
                            .outerHTML();
                    },
                },
                {
                    data: "action",
                },
                {
                    data: "formatted",
                },
            ],
            drawCallback: function(settings)
            {
                $target.find('.timeago').prettyTimestamp();
            },
        });
}
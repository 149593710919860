import { identity } from './user';
import { api, ApiOptionsSilentError } from './api';
import mqtt from 'mqtt/dist/mqtt.esm';

const MQTT_TOPIC = 'xx0r_machines/events';

async function init_mqtt()
{
    const id = await identity;
    if (id === null) {
        console.info("identity is null; not attempting to init mqtt");
        return;
    }

    if (!id.has_ability('mqtt.read')) {
        console.info(`user ${id.source}:${id.identity} doesn't have "mqtt.read" ability; not attempting to init mqtt`);
        return;
    }

    let accessToken = null;
    let expires = new Date(0);

    const renewAccessToken = async () => {
        if ((new Date()).getTime() < expires.getTime()) {
            console.info("not renewing access token for mqtt: does not expire until", expires);
            return;
        }

        console.info("renewing access token for mqtt");

        try {
            accessToken = await api('POST', 'oauth/token', {
                'grant_type': 'session',
                'scope': 'mqtt.read',
            }, new ApiOptionsSilentError());

            expires = new Date((new Date()).getTime() + (1000 * (accessToken.expires_in - 5)));
        }
        catch (e) {
            console.warn("failed to renew access token for mqtt:", e);
            accessToken = null;
            throw(e);
        }
    };

    await renewAccessToken();
    
    const transformWsUrl = (url, options, client) => {
        if (accessToken === null) {
            client.options.reconnectPeriod = Math.min(30000, 2*client.options.reconnectPeriod);
            console.error("no valid mqtt access token on hand, incrementing backoff");
            // unfortunately, throwing an exception here prevents reconnect attempts, so we have to
            // let the client attempt a connection
            return url;
        }
        // reset backoff
        client.options.reconnectPeriod = 1000;

        client.options.username = accessToken.access_token;
        client.options.password = 'x';

        return url;
    };
    
    let href = new URL(window.location.href);
    href.pathname = '/events';
    href.protocol = 'wss';
    const wssUrl = href.toString();

    const mqttClient = mqtt.connect(wssUrl, { 'transformWsUrl': transformWsUrl });
    
    mqttClient.on("disconnect", renewAccessToken);
    mqttClient.on("reconnect", renewAccessToken);
    mqttClient.on("connect", async () => {
        console.info("MQTT connected");
        await mqttClient.subscribeAsync(MQTT_TOPIC);
        console.info("MQTT subscribed to:", MQTT_TOPIC);
    });
    mqttClient.on("message", async (topic, message) => {
        let event;

        try {
            const messageStr = (new TextDecoder()).decode(message);

            event = JSON.parse(messageStr);
        } catch (e) {
            console.error("failed to decode mqtt message to json", message, e);
            return
        }

        console.debug('Received event via MQTT:', event);

        let event_bindings = state_history[0].event_bindings || [];
        if (event_bindings.length == 0 && state_history[0].derived_from) {
            for (const sh of state_history) {
                if (sh.page == state_history[0].derived_from) {
                    event_bindings = sh.event_bindings || [];
                    break;
                }
            }
        }
        for (const binding of event_bindings) {
            if (event_matches(event, binding.match)) {
                try {
                    binding.action(event);
                } catch (e) {
                    console.error("event handler for mqtt message failed:", event, e);
                }
            }
        }
    });
}

function event_matches(msg, match) {
    for (const [key, value] of Object.entries(match)) {
        if (msg[key] === undefined) {
            return false;
        }
        if (value === '*') {
            continue;
        }
        if (typeof(msg[key]) !== typeof(value)) {
            return false;
        }
        if (typeof(value) === 'object') {
            if (!event_matches(msg[key], value)) {
                return false;
            }
        } else {
            if (msg[key] !== value) {
                return false;
            }
        }
    }

    return true;
}

function bind_mqtt_event(match, action) {
    if (!state_history[0].event_bindings) {
        state_history[0].event_bindings = [];
    }

    state_history[0].event_bindings.push({match, action});
}

$(window).bind('singlepagesetup', function()
	{
        init_mqtt();
    });

export {
    bind_mqtt_event,
};
import Swal from 'sweetalert2';

import { add_route } from './singlepage';
import { api } from './api';
import { alert_success } from './alert-banners';

$(window).bind('singlepagesetup', function()
	{
	    add_route('domain/:domain(guid)/range/:range(guid)', async function(args)
			{
			    const [reservations, domain] = await Promise.all([
                    api('GET', `range/${args.range}/reservations`),
                    api('GET', `domain/${args.domain}`),
                ]);

                let range = reservations.range;
                range.reservations = reservations.reservations;
                range.domain = domain;

                const container = await load_template('range/edit', range);
                container.find('form[name="range"]').on('success', (event, result) => {
                    alert_success(`Successfully saved the range "${result.name}."`);
                });
			});

		add_route('domain/:domain(guid)/range/:range(guid)/delete', async function(args)
		    {
		        await derive_from(`domain/${args.domain}/range/${args.range}`);

		        const range = await api('GET', `range/${args.range}`);
		        const answer = await Swal.fire({
                    icon: 'question',
                    title: `Delete the range "${range.name}"?`,
                    text: `Existing reservations won't be affected.`,
                    showCancelButton: true,
                    confirmButtonText: 'Delete Range',
                });

                if (answer.isConfirmed) {
                    await api('DELETE', `range/${range.id}`);
                    alert_success(`Deleted range "${range.name}"`);
                    navigate_to(`domain/${args.domain}`);
                }
                else {
                    navigate_to(`domain/${args.domain}/range/${args.range}`);
                }
		    });

		add_route('domain/:domain(guid)/range/create', async function(args)
			{
			    const [domain] = await Promise.all([
                    api('GET', `domain/${args.domain}`),
                ]);

                const container = await load_template('range/edit', {domain});
                container.find('form[name="range"]').on('success', (event, result) => {
                    alert_success(`Successfully created the range "${result.name}."`);
                    navigate_to(`domain/${result.domain}/range/${result.id}`);
                });
			});
	});
import Swal from 'sweetalert2';

import { api } from './api';
import { add_route, load_content, parse_query } from './singlepage';
import { load_template } from './ui';
import { identity, init_identity, redirect_to_login } from './user';
import { alert_error } from './alert-banners';

$(window).bind('singlepagesetup', function()
	{
	    add_route('unregistered', async function(args)
			{
			    const id = await identity;
				console.debug(id);
			    const query = parse_query();
			    let whoami;
			    if (query.ip && query.mac) {
			        whoami = {
			            ip: query.ip,
			            iface: {
			                hardware_address: query.mac,
			            },
			        };
			        $.extend(whoami, await api('GET', `whoami/${query.mac}`));
			    }
			    else {
			        whoami = await api('GET', 'whoami');
			    }

				let template_args = {whoami, id};
				if (id.identity) {
					template_args.suggested_hostname = (await api('GET', 'host/random')).name;
				}

			    const container = await load_template('host/unregistered', template_args);

			    container.find('a[rel="redirect-to-login"]').on('click', async (event) => {
                    redirect_to_login();

                    return false;
			    });

				container.find('a[rel="guest-login"]').on('click', async (event) => {
                    let guestPw = await Swal.fire({
						title: "Guest login",
						text: "To continue, please enter the guest access password.",
						input: "password",
						showCancelButton: true,
						showLoaderOnConfirm: true,
						preConfirm: async function (inp) {
							if (inp === '') {
								alert_error("Please enter a password.");
								return false;
							}
						},
					});

					if (!guestPw.isConfirmed) {
						return false;
					}

					let apiResult = await api('POST', 'user/login/guest', {'password': guestPw.value});

					console.debug(apiResult);

					if (apiResult.ok) {
						init_identity();
						await identity;
						let random_hostname = api('GET', 'host/random');
						await load_content('unregistered');
						container.find('input[name="name"]').val((await random_hostname).name);

					} else {
						alert_error(`Unable to complete guest login. Double-check the password and try again.`);
					}

                    return false;
			    });

                container.find('form').on('success', async (event, result) => {
                    await Swal.fire({
                        icon: 'success',
                        title: 'Good to go!',
                        text: 'Thanks for registering your system. You can now use network resources and the internet!',
                    });

                    if (query.return_to) {
                        window.location.href = query.return_to;
                    }
                });
			}, { loginRequired: false });
	});
